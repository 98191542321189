import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerProducto() {
    var url = ConfigAPI.baseURL + "products" + Session.getToken();
    return instance.get(url);
  },
  mostrarProducto(id) {
    var url = ConfigAPI.baseURL + "products/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarProducto(data, image1, file, file1=null, file2=null, file3=null) {  
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }
      if(key=="file1") {             
        if(file1) {
          file1.forEach((value, key) => {          
            formData.append('file1', value);
          })
        }
      }     

      if(key=="file2") {                
        if(file2) {
          file2.forEach((value, key) => {          
            formData.append('file2', value);
          })
        }
      }     
      
      if(key=="file3") {          
        if(file3) {
          file3.forEach((value, key) => {          
            formData.append('file3', value);
          })
        }
      }        
    }

    var params = formData
    var url = ConfigAPI.baseURL + "products" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarProducto(data, image1, file, file1=null, file2=null, file3=null) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }     
      
      if(key=="file1") {             
        if(file1) {
          file1.forEach((value, key) => {          
            formData.append('file1', value);
          })
        }
      }     

      if(key=="file2") {                
        if(file2) {
          file2.forEach((value, key) => {          
            formData.append('file2', value);
          })
        }
      }     
      
      if(key=="file3") {          
        if(file3) {
          file3.forEach((value, key) => {          
            formData.append('file3', value);
          })
        }
      }           
    }

    var params = formData
    var url = ConfigAPI.baseURL + "products/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarProducto(id) {
    var url = ConfigAPI.baseURL + "products/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarProducto(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "products/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },
  editarProductStock(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-stock/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },   
  editarProductStockVariedad(data, typeVariant){
    var url = ""
    var params = data    

    if(typeVariant=='COLOR') {
      url = ConfigAPI.baseURL + "products-products-colors-stock/"+ data.id + Session.getToken();
    }    
    if(typeVariant=='WAIST') {
      url = ConfigAPI.baseURL + "products-products-waist-stock/"+ data.id + Session.getToken();
    } 
    if(typeVariant=='COLOR-WAIST') {
      url = ConfigAPI.baseURL + "products-products-colors-waist-stock/"+ data.id + Session.getToken();
    }
        
    return instance.post(url,params);        
  },
  
  importarProductos(data, file, type='product') {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ''
    if(type == 'product') {
      url = ConfigAPI.baseURL + "products/import/CSV" + Session.getToken();
    } 
    if (type == 'product-colors') {
      url = ConfigAPI.baseURL + "products-products-colors/import/CSV" + Session.getToken();
    }
    if (type == 'product-waist') {
      url = ConfigAPI.baseURL + "products-products-waist/import/CSV" + Session.getToken();
    }    
    if (type == 'product-colors-waist') {
      url = ConfigAPI.baseURL + "products-products-colors-waist/import/CSV" + Session.getToken();
    }        
    
    return instance.post(url,params,{headers});
  },
  importarUpdateProductosPrices(data, file, type='product') {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }

      if(key=="file") {                
        file.forEach((value, key) => {          
          formData.append('file', value);
        })
      }      
    }

    var params = formData
    var url = ''
    if(type == 'product') {
      url = ConfigAPI.baseURL + "price-list-products/import/CSV" + Session.getToken();
    }
    if (type == 'product-colors') {
      url = ConfigAPI.baseURL + "price-list-products-products-colors/import/CSV" + Session.getToken();
    }
    if (type == 'product-waist') {
      url = ConfigAPI.baseURL + "price-list-products-products-waist/import/CSV" + Session.getToken();
    }    
    if (type == 'product-colors-waist') {
      url = ConfigAPI.baseURL + "price-list-products-products-colors-waist/import/CSV" + Session.getToken();
    }

    return instance.post(url,params,{headers});
  },  

  obtenerProductoCompuesto() {
    var url = ConfigAPI.baseURL + "products-compound" + Session.getToken();
    return instance.get(url);
  },
  mostrarProductoCompuesto(id) {
    var url = ConfigAPI.baseURL + "products-compound/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarProductoCompuesto(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-compound" + Session.getToken();
    return instance.post(url,params);
  },
  editarProductoCompuesto(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-compound/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarProductoCompuesto(id) {
    var url = ConfigAPI.baseURL + "products-compound/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarProductoCompuesto(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-compound/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },

  agregarProductoCompuestoDetalle(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-compound-detail" + Session.getToken();
    return instance.post(url,params);
  },
  editarProductoCompuestoDetalle(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-compound-detail/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarProductoCompuestoDetalle(id) {
    var url = ConfigAPI.baseURL + "products-compound-detail/"+ id + Session.getToken();
    return instance.delete(url);
  },  

  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "products-category" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategoria(id) {
    var url = ConfigAPI.baseURL + "products-category/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategoria(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "products-category" + Session.getToken();
    return instance.post(url,params,{headers});    
  },
  editarCategoria(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData   
    var url = ConfigAPI.baseURL + "products-category/"+ data.id + Session.getToken();    
    return instance.post(url,params,{headers});
  },
  eliminarCategoria(id) {
    var url = ConfigAPI.baseURL + "products-category/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerLinea() {
    var url = ConfigAPI.baseURL + "products-line" + Session.getToken();
    return instance.get(url);
  },
  mostrarLinea(id) {
    var url = ConfigAPI.baseURL + "products-line/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarLinea(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-line" + Session.getToken();
    return instance.post(url,params);
  },
  editarLinea(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-line/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarLinea(id) {
    var url = ConfigAPI.baseURL + "products-line/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerColor() {
    var url = ConfigAPI.baseURL + "products-colors" + Session.getToken();
    return instance.get(url);
  },
  mostrarColor(id) {
    var url = ConfigAPI.baseURL + "products-colors/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarColor(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="plot") {
        formData.append(key, data[key]);
      }

      if(key=="plot") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('plot_' + i, value);
          i++
        })
        formData.append('plot', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "products-colors" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarColor(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="plot") {
        formData.append(key, data[key]);
      }

      if(key=="plot") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('plot_' + i, value);
          i++
        })
        formData.append('plot', i);
      }
    }

    var params = formData   
    var url = ConfigAPI.baseURL + "products-colors/"+ data.id + Session.getToken();    
    return instance.post(url,params,{headers});
  },
  eliminarColor(id) {
    var url = ConfigAPI.baseURL + "products-colors/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerTalle() {
    var url = ConfigAPI.baseURL + "products-waist" + Session.getToken();
    return instance.get(url);
  },
  mostrarTalle(id) {
    var url = ConfigAPI.baseURL + "products-waist/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarTalle(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-waist" + Session.getToken();
    return instance.post(url,params);
  },
  editarTalle(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-waist/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarTalle(id) {
    var url = ConfigAPI.baseURL + "products-waist/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerSublinea() {
    var url = ConfigAPI.baseURL + "products-subline" + Session.getToken();
    return instance.get(url);
  },
  mostrarSublinea(id) {
    var url = ConfigAPI.baseURL + "products-subline/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSublinea(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-subline" + Session.getToken();
    return instance.post(url,params);
  },
  editarSublinea(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-subline/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSublinea(id) {
    var url = ConfigAPI.baseURL + "products-subline/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerSubcategoria() {
    var url = ConfigAPI.baseURL + "products-subcategory" + Session.getToken();
    return instance.get(url);
  },
  mostrarSubcategoria(id) {
    var url = ConfigAPI.baseURL + "products-subcategory/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSubcategoria(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-subcategory" + Session.getToken();
    return instance.post(url,params);
  },
  editarSubcategoria(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-subcategory/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSubcategoria(id) {
    var url = ConfigAPI.baseURL + "products-subcategory/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  agregarVariedad(data, image1, typeVariant) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }
    
    var url = ""
    var params = formData    

    if(typeVariant=='COLOR') {
      url = ConfigAPI.baseURL + "products-products-colors" + Session.getToken();
    }    
    if(typeVariant=='WAIST') {
      url = ConfigAPI.baseURL + "products-products-waist" + Session.getToken();
    } 
    if(typeVariant=='COLOR-WAIST') {
      url = ConfigAPI.baseURL + "products-products-colors-waist" + Session.getToken();
    }
    
    return instance.post(url,params,{headers});
  },
  editarVariedad(data, image1, typeVariant) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var url = ""
    var params = formData
    if(typeVariant=='COLOR') {
      url = ConfigAPI.baseURL + "products-products-colors/" + data.id + Session.getToken();
    } 
    if(typeVariant=='WAIST') {
      url = ConfigAPI.baseURL + "products-products-waist/" + data.id + Session.getToken();
    } 
    if(typeVariant=='COLOR-WAIST') {
      url = ConfigAPI.baseURL + "products-products-colors-waist/" + data.id + Session.getToken();
    }    
        
    return instance.post(url,params, {headers});
  },
  eliminarVariedad(id, typeVariant) {       
    var url = ""
    if(typeVariant=='COLOR') {      
      url = ConfigAPI.baseURL + "products-products-colors/"+ id + Session.getToken();
    } 
    if(typeVariant=='WAIST') {
      url = ConfigAPI.baseURL + "products-products-waist/"+ id + Session.getToken();        
    } 
    if(typeVariant=='COLOR-WAIST') {      
      url = ConfigAPI.baseURL + "products-products-colors-waist/"+ id + Session.getToken();
    } 

    return instance.delete(url);
  },   

  obtenerListaPrecios() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },  
  obtenerListaPreciosSimple() {
    var url = ConfigAPI.baseURL + "price-list-simple" + Session.getToken();
    return instance.get(url);
  },  

  editarPrecioVariedadItem(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-products-item/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },  

  agregarPrecioVariedad(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products" + Session.getToken();
    return instance.post(url,params);
  },
  editarPrecioVariedad(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-products/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },
  eliminarPrecioVariedad(id) {       
    var url = ConfigAPI.baseURL + "price-list-products/"+ id + Session.getToken();
    return instance.delete(url);
  },  

  agregarPrecioProductoCompuesto(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-products-compound" + Session.getToken();
    return instance.post(url,params);
  },
  editarPrecioProductoCompuesto(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-products-compound/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },
  eliminarPrecioProductoCompuesto(id) {       
    var url = ConfigAPI.baseURL + "price-list-products-compound/"+ id + Session.getToken();
    return instance.delete(url);
  },      

  obtenerProductMarketplace() {
    var url = ConfigAPI.baseURL + "products-marketplace" + Session.getToken();
    return instance.get(url);
  },
  mostrarProductMarketplace(id) {
    var url = ConfigAPI.baseURL + "products-marketplace/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarProductMarketplace(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace" + Session.getToken();
    return instance.post(url,params);
  },
  editarProductMarketplace(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-marketplace/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarProductMarketplace(id) {
    var url = ConfigAPI.baseURL + "products-marketplace/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filterProductMarketplace(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-marketplace/filter/get" + Session.getToken();
    return instance.post(url,params);
  }, 
  filterSoldMarketplace(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-marketplace/filter/get/sold" + Session.getToken();
    return instance.post(url,params);
  }, 

  checkSoldProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/check/sold" + Session.getToken();
    return instance.post(url,params);
  },

  checkPaymentProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/check/payment" + Session.getToken();
    return instance.post(url,params);
  },
  uncheckPaymentProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/uncheck/payment" + Session.getToken();
    return instance.post(url,params);
  },
  checkActiveProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/check/active" + Session.getToken();
    return instance.post(url,params);
  },
  uncheckActiveProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/uncheck/active" + Session.getToken();
    return instance.post(url,params);
  },
  checkInvoiceProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/check/invoice" + Session.getToken();
    return instance.post(url,params);
  },
  uncheckInvoiceProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/uncheck/invoice" + Session.getToken();
    return instance.post(url,params);
  },
  checkPublishedInstagramProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products/check/published-instagram" + Session.getToken();
    return instance.post(url,params);
  },
  uncheckPublishedInstagramProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products/uncheck/published-instagram" + Session.getToken();
    return instance.post(url,params);
  },
  messageSendProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/send/message" + Session.getToken();
    return instance.post(url,params);
  },  
  refusedProductMarketplace(data) {
    var params = data
    var url = ConfigAPI.baseURL + "products-marketplace/refused/product" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },  

  obtenerUnidadesMedidas() {
    var url = ConfigAPI.baseURL + "products-unit-measurement" + Session.getToken();
    return instance.get(url);
  },
  mostrarUnidadMedida(id) {
    var url = ConfigAPI.baseURL + "products-unit-measurement/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarUnidadMedida(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "products-unit-measurement" + Session.getToken();
    return instance.post(url,params);
  },
  editarUnidadMedida(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarUnidadMedida(id) {
    var url = ConfigAPI.baseURL + "products-unit-measurement/"+ id + Session.getToken();
    return instance.delete(url);
  },   

  obtenerEquivalencesAvailable(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/filter/equivalenceAvailable" + Session.getToken();
    return instance.post(url,params);            
  },
  
  recalculePriceByUnitMeasurementAlternative(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/recalcule/price" + Session.getToken();
    return instance.post(url,params);                
  },  

  obtenerDepositoZona() {      
    var url = ConfigAPI.baseURL + "deposits-zone" + Session.getToken();
    return instance.get(url);        
  },  
}

export default servicesAPI;
